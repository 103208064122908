import styled from 'styled-components';

import {
  FiTv,
  FiUsers,
  FiMapPin,
  FiYoutube,
  FiBarChart2,
  FiAlertTriangle,
} from 'react-icons/fi';
import { MdOutlineMonetizationOn } from 'react-icons/md';

import logo from '../../assets/images/logo-yellow.png';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  padding: 2rem;
  min-width: 22rem;
  border-right: 2px solid var(--color-black);
  background: var(--color-form-background);
`;

export const MenuWrapper = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: ${({ image }) => (image ? '6rem' : '4rem')};

  cursor: pointer;
`;

export const Logo = styled.img.attrs({
  src: logo,
  alt: 'TemLance',
})`
  width: 4rem;
  height: 4rem;
  margin-right: 2rem;
`;

export const Title = styled.span`
  font-size: 2rem;
  color: var(--color-white);
`;

export const MenuText = styled.span`
  margin-left: 2rem;
  font-size: 1.8rem;
  color: ${({ isSelected }) => (
    isSelected ? 'var(--color-primary)' : 'var(--color-white)'
  )};
`;

export const ComplaintsIcon = styled(FiAlertTriangle).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;

export const PlacesIcon = styled(FiMapPin).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;

export const AdsIcon = styled(FiYoutube).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;

export const MonetizationIcon = styled(MdOutlineMonetizationOn).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;

export const TVIcon = styled(FiTv).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;

export const IndicatorsIcon = styled(FiBarChart2).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;

export const UsersIcon = styled(FiUsers).attrs(({ isSelected }) => ({
  size: 22,
  color: isSelected ? 'var(--color-primary)' : 'var(--color-white)',
}))``;
