import React from 'react';

import styled from 'styled-components';
import ReactModal from 'react-modal';
import {
  FiEdit,
  FiLock,
  FiTrash,
  FiUnlock,
} from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8rem;
`;

export const Widget = styled.div`
  position: relative;
  width: 60%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding: 2rem 0 4rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-form-border);
  background: var(--color-form-background);
`;

export const WidgetHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--color-form-border);
`;

export const TableTitle = styled.span`
  width: 50%;
  margin-left: 2rem;
  color: var(--color-white);
`;

export const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  margin-right: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  font: 400 1.6rem Maven Pro;
  color: var(--color-black);
  background: var(--color-primary);

  :hover {
    background: var(--color-primary-dark);
  }
`;

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-background);
  border-bottom: 1px solid var(--color-form-border);
  opacity: ${({ active }) => (active ? 1 : 0.3)};
`;

export const ImageAndTextWrapper = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  margin: 1rem 2rem;
  border-radius: 50%;
  background: var(--color-primary);

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.4rem;
  padding-left: 1px;
  padding-top: 3px;
  font-family: Bebas Neue;
  text-transform: uppercase;
  color: var(--color-black);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.span`
  font-size: 1.6rem;
  color: var(--color-white);
`;

export const Email = styled(Name)`
  font-size: 1.2rem;
  color: #ccc;
`;

export const Role = styled(Name)`
  margin-left: 2rem;
`;

export const ProfileWrapper = styled.div`
  width: 50%;
`;

export const Edit = styled(FiEdit).attrs({
  size: 22,
  color: 'var(--color-primary)',
})`
  margin-right: 2rem;
  cursor: pointer;
`;

export const Unlock = styled(FiUnlock).attrs({
  size: 22,
  color: 'var(--color-primary)',
})`
  margin-right: 2rem;
  cursor: pointer;
`;

export const Lock = styled(FiLock).attrs({
  size: 22,
  color: 'var(--color-primary)',
})`
  margin-right: 2rem;
  cursor: pointer;
`;

const ReactModalAdapter = ({ className, modalClassName, ...props }) => (
  <ReactModal
    className={modalClassName}
    portalClassName={className}
    bodyOpenClassName="portalOpen"
    {...props}
  />
);

export const Modal = styled(ReactModalAdapter).attrs({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay',
})`
  & .Overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  & .Modal {
    display: flex;
    flex: 1;

    width: 100%;
    padding: 2rem;
    border-radius: 4px;
    border: 1px solid var(--color-form-border);
    background: var(--color-form-background);

    outline: none;

    position: absolute;
    top: 10%;
  }
`;

export const FormWrapper = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalMain = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const LabelInputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: ${({ email }) => (email ? `${2}rem` : 0)};
`;

export const Label = styled.label`
  margin-left: 0.5rem;
  color: var(--color-white);
`;

export const Input = styled.input`
  width: 100%;
  height: 3.6rem;
  padding: 0 1rem;
  margin-top: 1rem;
  border-radius: 0.6rem;
  border: 1px solid var(--color-input-border);
  background: var(--color-input-background);
`;

export const ModalFooter = styled.div`
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-end;
`;

export const ModalEditFooter = styled(ModalFooter)`
  justify-content: space-between;
`;

export const ModalPermissionFooter = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--color-form-border);
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxWrapper = styled(ButtonsWrapper)`
  cursor: pointer;
`;

export const RadioWrapper = styled(ButtonsWrapper)`
  width: 60px;
  cursor: pointer;
`;

export const RadioWrapperDisabled = styled(RadioWrapper)`
  cursor: default;
`;

export const ConfirmButton = styled(AddButton)`
  margin: 0;
  min-width: 10rem;
`;

export const Cancel = styled(Name)`
  margin-right: 2rem;
  cursor: pointer;
`;

export const DeleteWrapper = styled(ButtonsWrapper)`
  align-self: start;
  margin-top: 0.5rem;

  cursor: pointer;
`;

export const Trash = styled(FiTrash).attrs({
  size: 22,
  color: 'var(--color-red)',
})`
  margin-left: -0.4rem;
`;

export const Delete = styled.span`
  margin-left: 0.5rem;
  color: ${({ clicked }) => (clicked ? 'var(--color-red)' : 'var(--color-white)')};
`;

export const CheckboxLabel = styled.label`
  margin-left: 1rem;
  color: var(--color-white);

  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;

  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: black;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  background: ${({ checked }) => (
    checked ? 'var(--color-primary)' : 'var(--color-input-background)'
  )};

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;

export const Checkbox = ({ className, checked, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export const ModalPermission = styled(Modal)`
  & .Modal {
    max-width: 540px;
    padding: 1.5rem;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ModalPermissionContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalPermissionMain = styled.div`
  display: flex;
`;

export const ModalPermissionText = styled.span`
  color: var(--color-white);
`;

export const FeatureTextWrapper = styled.div`
  height: 3rem;
  display: flex;
  align-items: center;
`;

export const FeatureText = styled.span`
  color: var(--color-white);
`;

export const ModalPermissionHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
`;

export const PermissionTypeWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const LeftMain = styled.div`
  flex: 1;
`;

export const RightMain = styled.div`
  flex: 1;
  margin: 0 2rem;
  padding-left: 6rem;
`;

export const LeftContent = styled(ModalPermissionContainer)``;

export const RightContent = styled.div`
  height: 3rem;
  display: flex;
`;

export const RadioButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid ${({ selected }) => (
    selected ? 'var(--color-primary)' : 'var(--color-white)'
  )};
`;

export const RadioSelected = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--color-primary);
`;

export const RadioDisabled = styled(RadioButton)`
  border: 2px solid var(--color-form-border);
`;
