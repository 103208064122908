import React from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import Tooltip from '../../components/Tooltip/Tooltip';
import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';
import Feature from '../../data/enums/feature';
import Permission from '../../data/enums/permission';

import { UserActions } from '../../redux/actions';

import { customLetters, onlyEmail } from '../../utils/maskedInput';

import * as S from './styles';

const UsersPage = () => {
  const {
    users,
    isLoading,
    errorMessage,
    minePermissions,
    isConfirmLoading,
    isRequestCompleted,
  } = useSelector(({ user }) => user);

  const [uid, setUid] = React.useState('');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [active, setActive] = React.useState(true);
  const [deleted, setDeleted] = React.useState(false);
  const [adsPermission, setAdsPermission] = React.useState([]);
  const [usersPermission, setUsersPermission] = React.useState([]);
  const [placesPermission, setPlacesPermission] = React.useState([]);
  const [tvVideosPermission, setTvVideosPermission] = React.useState([]);
  const [complaintsPermission, setComplaintsPermission] = React.useState([]);
  const [indicatorsPermission, setIndicatorsPermission] = React.useState([]);
  const [visibleAddUserModal, setVisibleAddUserModal] = React.useState(false);
  const [visibleEditUserModal, setVisibleEditUserModal] = React.useState(false);
  const [showModalPermissions, setShowModalPermissions] = React.useState(false);
  const [discountClubPermission, setDiscountClubPermission] = React.useState([]);

  const widgetRef = React.useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasAdminPermission = minePermissions
    && minePermissions.find(
      ({ feature, permission }) => feature === Feature.USERS
      && permission === Permission.ADMIN,
    );

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  React.useEffect(() => {
    if (isRequestCompleted) {
      setName('');
      setEmail('');
      setDeleted(false);
      setVisibleAddUserModal(false);
      setVisibleEditUserModal(false);
      setShowModalPermissions(false);
    }
  }, [isRequestCompleted]);

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.USERS));

    dispatch(UserActions.getUsers());
  }, []);

  const Roles = ({ userPermissions }) => {
    if (userPermissions) {
      if (!userPermissions.length) {
        return <S.Role>{t('noPermission')}</S.Role>;
      }

      if (
        userPermissions.every(
          ({ permission }) => permission === Permission.ADMIN,
        )
      ) {
        return <S.Role>{t('administrator')}</S.Role>;
      }

      if (
        userPermissions.some(
          ({ permission }) => permission === Permission.ADMIN,
        )
      ) {
        return <S.Role>{t('editor')}</S.Role>;
      }

      if (
        userPermissions.some(
          ({ permission }) => permission === Permission.VIEWER,
        )
      ) {
        return <S.Role>{t('viewer')}</S.Role>;
      }

      if (
        userPermissions.some(
          ({ permission }) => permission === Permission.PLACE_OWNER,
        )
      ) {
        return <S.Role>{t('owner')}</S.Role>;
      }
    }

    return null;
  };

  const verifyEmail = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const onCancelAdd = () => {
    setVisibleAddUserModal(false);
    setName('');
    setEmail('');
  };

  const onCancelEdit = () => {
    setName('');
    setEmail('');
    setDeleted(false);
    setAdsPermission([]);
    setUsersPermission([]);
    setPlacesPermission([]);
    setTvVideosPermission([]);
    setComplaintsPermission([]);
    setIndicatorsPermission([]);
    setVisibleEditUserModal(false);
    setShowModalPermissions(false);
  };

  const onAddUser = (e) => {
    e.preventDefault();

    if (!name.length) {
      toast.error(t('typeName'));

      return;
    }

    if (!verifyEmail()) {
      toast.error(t('invalidEmail'));

      return;
    }

    dispatch(
      UserActions.addUser({
        name: name.trim(),
        email: email.trim().toLowerCase(),
        password: '123456',
      }),
    );
  };

  const onEditUser = (e) => {
    e.preventDefault();

    if (!name.length) {
      toast.error(t('typeName'));

      return;
    }

    dispatch(
      UserActions.updateUser({
        uid,
        name: name.trim(),
        activated: active,
      }),
    );
  };

  const onDeleteUser = (e) => {
    e.preventDefault();

    dispatch(UserActions.deleteUser(uid));
  };

  const handleEditUser = (user) => {
    if (hasAdminPermission) {
      setUid(user.uid);
      setName(user.name);
      setEmail(user.email);
      setActive(user.activated);
      setVisibleEditUserModal(true);
    } else {
      toast.error(t('noPermission'));
    }
  };

  const handleUpdateUserPermission = ({ uid: userUid }) => {
    if (hasAdminPermission) {
      const { permissions } = users.find((user) => user.uid === userUid);

      const foundComplaints = permissions.find(
        (item) => item.feature === Feature.COMPLAINTS,
      );

      if (foundComplaints) {
        setComplaintsPermission([foundComplaints]);
      }

      const foundPlaces = permissions.find(
        (item) => item.feature === Feature.PLACES,
      );

      if (foundPlaces) {
        setPlacesPermission([foundPlaces]);
      }

      const foundAds = permissions.find(
        (item) => item.feature === Feature.ADS,
      );

      if (foundAds) {
        setAdsPermission([foundAds]);
      }

      const foundDiscountClub = permissions.find(
        (item) => item.feature === Feature.DISCOUNT_CLUB,
      );

      if (foundDiscountClub) {
        setDiscountClubPermission([foundDiscountClub]);
      }

      const foundTVVideos = permissions.find(
        (item) => item.feature === Feature.TV_VIDEOS,
      );

      if (foundTVVideos) {
        setTvVideosPermission([foundTVVideos]);
      }

      const foundIndicators = permissions.find(
        (item) => item.feature === Feature.INDICATORS,
      );

      if (foundIndicators) {
        setIndicatorsPermission([foundIndicators]);
      }

      const foundUsers = permissions.find(
        (item) => item.feature === Feature.USERS,
      );

      if (foundUsers) {
        setUsersPermission([foundUsers]);
      }

      setUid(userUid);
      setShowModalPermissions(true);
    } else {
      toast.error(t('noPermission'));
    }
  };

  const handleComplaintsPermission = ({ permission }) => {
    const alreadySelected = complaintsPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setComplaintsPermission(
        complaintsPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setComplaintsPermission([
        {
          feature: Feature.COMPLAINTS,
          permission,
        },
      ]);
    }
  };

  const handlePlacesPermission = ({ permission }) => {
    const alreadySelected = placesPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setPlacesPermission(
        placesPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setPlacesPermission([
        {
          feature: Feature.PLACES,
          permission,
        },
      ]);
    }
  };

  const handleAdsPermission = ({ permission }) => {
    const alreadySelected = adsPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setAdsPermission(
        adsPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setAdsPermission([
        {
          feature: Feature.ADS,
          permission,
        },
      ]);
    }
  };

  const handleDiscountClubPermission = ({ permission }) => {
    const alreadySelected = discountClubPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setDiscountClubPermission(
        discountClubPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setDiscountClubPermission([
        {
          feature: Feature.DISCOUNT_CLUB,
          permission,
        },
      ]);
    }
  };

  const handleTvVideosPermission = ({ permission }) => {
    const alreadySelected = tvVideosPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setTvVideosPermission(
        tvVideosPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setTvVideosPermission([
        {
          feature: Feature.TV_VIDEOS,
          permission,
        },
      ]);
    }
  };

  const handleIndicatorsPermission = ({ permission }) => {
    const alreadySelected = indicatorsPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setIndicatorsPermission(
        indicatorsPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setIndicatorsPermission([
        {
          feature: Feature.INDICATORS,
          permission,
        },
      ]);
    }
  };

  const handleUsersPermission = ({ permission }) => {
    const alreadySelected = usersPermission.find(
      (item) => item.permission === permission,
    );

    if (alreadySelected) {
      setUsersPermission(
        usersPermission.filter((item) => item.permission !== permission),
      );
    } else {
      setUsersPermission([
        {
          feature: Feature.USERS,
          permission,
        },
      ]);
    }
  };

  const handleConfirmPermissions = () => {
    dispatch(
      UserActions.updateUserPermissions({
        uid,
        permissions: [
          ...complaintsPermission,
          ...placesPermission,
          ...adsPermission,
          ...discountClubPermission,
          ...tvVideosPermission,
          ...indicatorsPermission,
          ...usersPermission,
        ],
      }),
    );
  };

  const handleAddUser = () => {
    if (hasAdminPermission) {
      setVisibleAddUserModal(true);
    } else {
      toast.error(t('noPermission'));
    }
  };

  return (
    <LoggedArea>
      <S.Container>
        {isLoading ? (
          <Loading />
        ) : (
          <S.Widget ref={widgetRef}>
            <S.WidgetHeader>
              <S.TableTitle>{t('user')}</S.TableTitle>

              <S.TableTitle>{t('profile')}</S.TableTitle>

              <S.AddButton onClick={() => handleAddUser()}>
                {t('add')}
              </S.AddButton>
            </S.WidgetHeader>

            {users
              ? users
                .sort((x, y) => y.activated - x.activated)
                .map((user) => (
                  <S.User key={user.uid} active={user.activated}>
                    <S.ImageAndTextWrapper>
                      <S.Avatar>{user.name.charAt(0)}</S.Avatar>

                      <S.Wrapper>
                        <S.Name>{user.name}</S.Name>
                        <S.Email>{user.email}</S.Email>
                      </S.Wrapper>
                    </S.ImageAndTextWrapper>

                    <S.ProfileWrapper>
                      <Roles userPermissions={user.permissions} />
                    </S.ProfileWrapper>

                    <Tooltip title={t('edit')}>
                      <S.Edit onClick={() => handleEditUser(user)} />
                    </Tooltip>

                    {user.permissions.length > 0 ? (
                      <Tooltip title={t('permissions')}>
                        <S.Unlock
                          onClick={() => handleUpdateUserPermission(user)}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={t('permissions')}>
                        <S.Lock
                          onClick={() => handleUpdateUserPermission(user)}
                        />
                      </Tooltip>
                    )}
                  </S.User>
                ))
              : null}

            {widgetRef && widgetRef.current ? (
              <S.Modal
                isOpen={visibleAddUserModal}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => onCancelAdd()}
                parentSelector={() => widgetRef.current}
              >
                <S.FormWrapper onSubmit={onAddUser}>
                  <S.ModalMain>
                    <S.LabelInputWrapper>
                      <S.Label>{t('name')}</S.Label>
                      <S.Input
                        value={name}
                        maxLength={30}
                        placeholder={t('typeName')}
                        onChange={(e) => setName(customLetters(e.target.value))}
                      />
                    </S.LabelInputWrapper>

                    <S.LabelInputWrapper email>
                      <S.Label>{t('email')}</S.Label>
                      <S.Input
                        type="email"
                        value={email}
                        maxLength={50}
                        placeholder={t('typeEmail')}
                        onChange={(e) => setEmail(
                          onlyEmail(e.target.value.toLowerCase()),
                        )}
                      />
                    </S.LabelInputWrapper>
                  </S.ModalMain>

                  <S.ModalFooter>
                    <S.ButtonsWrapper>
                      <S.Cancel onClick={() => onCancelAdd()}>
                        {t('cancel')}
                      </S.Cancel>

                      <S.ConfirmButton>
                        {isConfirmLoading ? (
                          <Loading
                            type="spin"
                            width={20}
                            height={20}
                            color="#000"
                          />
                        ) : (
                          t('confirm')
                        )}
                      </S.ConfirmButton>
                    </S.ButtonsWrapper>
                  </S.ModalFooter>
                </S.FormWrapper>
              </S.Modal>
            ) : null}

            {widgetRef && widgetRef.current ? (
              <S.Modal
                isOpen={visibleEditUserModal}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => onCancelEdit()}
                parentSelector={() => widgetRef.current}
              >
                <S.FormWrapper onSubmit={deleted ? onDeleteUser : onEditUser}>
                  <S.ModalMain>
                    <S.LabelInputWrapper>
                      <S.Label>{t('name')}</S.Label>
                      <S.Input
                        value={name}
                        maxLength={30}
                        placeholder={t('typeName')}
                        onChange={(e) => setName(customLetters(e.target.value))}
                      />
                    </S.LabelInputWrapper>

                    <S.LabelInputWrapper email>
                      <S.Label>{t('email')}</S.Label>
                      <S.Input disabled value={email} />
                    </S.LabelInputWrapper>
                  </S.ModalMain>

                  <S.ModalEditFooter>
                    <S.CheckboxWrapper onClick={() => setActive(!active)}>
                      <S.Checkbox checked={active} />
                      <S.CheckboxLabel>{t('active')}</S.CheckboxLabel>
                    </S.CheckboxWrapper>

                    <S.ButtonsWrapper>
                      <S.Cancel onClick={() => onCancelEdit()}>
                        {t('cancel')}
                      </S.Cancel>

                      <S.ConfirmButton>
                        {isConfirmLoading ? (
                          <Loading
                            type="spin"
                            width={20}
                            height={20}
                            color="#000"
                          />
                        ) : (
                          t('confirm')
                        )}
                      </S.ConfirmButton>
                    </S.ButtonsWrapper>
                  </S.ModalEditFooter>

                  <S.DeleteWrapper onClick={() => setDeleted(true)}>
                    <S.Trash />
                    <S.Delete clicked={deleted}>{t('delete')}</S.Delete>
                  </S.DeleteWrapper>
                </S.FormWrapper>
              </S.Modal>
            ) : null}

            {widgetRef && widgetRef.current ? (
              <S.ModalPermission
                isOpen={showModalPermissions}
                shouldCloseOnOverlayClick={false}
                parentSelector={() => widgetRef.current}
              >
                <S.ModalPermissionContainer>
                  <S.ModalPermissionHeader>
                    <S.PermissionTypeWrapper>
                      <S.ModalPermissionText>
                        {t('admin')}
                      </S.ModalPermissionText>
                      <S.ModalPermissionText>
                        {t('reading')}
                      </S.ModalPermissionText>
                      <S.ModalPermissionText>
                        {t('owner')}
                      </S.ModalPermissionText>
                    </S.PermissionTypeWrapper>
                  </S.ModalPermissionHeader>

                  <S.ModalPermissionMain>
                    <S.LeftMain>
                      <S.LeftContent>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('complaints')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('places')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('ads')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('discountClub')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('tvVideos')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('indicators')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                        <S.FeatureTextWrapper>
                          <S.FeatureText>{t('users')}</S.FeatureText>
                        </S.FeatureTextWrapper>
                      </S.LeftContent>
                    </S.LeftMain>

                    <S.RightMain>
                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handleComplaintsPermission({
                              feature: Feature.COMPLAINTS,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={complaintsPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {complaintsPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handleComplaintsPermission({
                              feature: Feature.COMPLAINTS,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={complaintsPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {complaintsPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapperDisabled>
                          <S.RadioDisabled />
                        </S.RadioWrapperDisabled>
                      </S.RightContent>

                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handlePlacesPermission({
                              feature: Feature.PLACES,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={placesPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {placesPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handlePlacesPermission({
                              feature: Feature.PLACES,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={placesPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {placesPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handlePlacesPermission({
                              feature: Feature.PLACES,
                              permission: Permission.PLACE_OWNER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={placesPermission.some(
                              (item) => item.permission === Permission.PLACE_OWNER,
                            )}
                          >
                            {placesPermission.some(
                              (item) => item.permission === Permission.PLACE_OWNER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                      </S.RightContent>

                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handleAdsPermission({
                              feature: Feature.ADS,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={adsPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {adsPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handleAdsPermission({
                              feature: Feature.ADS,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={adsPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {adsPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapperDisabled>
                          <S.RadioDisabled />
                        </S.RadioWrapperDisabled>
                      </S.RightContent>

                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handleDiscountClubPermission({
                              feature: Feature.DISCOUNT_CLUB,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={discountClubPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {discountClubPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handleDiscountClubPermission({
                              feature: Feature.DISCOUNT_CLUB,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={discountClubPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {discountClubPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapperDisabled>
                          <S.RadioDisabled />
                        </S.RadioWrapperDisabled>
                      </S.RightContent>

                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handleTvVideosPermission({
                              feature: Feature.TV_VIDEOS,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={tvVideosPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {tvVideosPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handleTvVideosPermission({
                              feature: Feature.TV_VIDEOS,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={tvVideosPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {tvVideosPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapperDisabled>
                          <S.RadioDisabled />
                        </S.RadioWrapperDisabled>
                      </S.RightContent>

                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handleIndicatorsPermission({
                              feature: Feature.INDICATORS,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={indicatorsPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {indicatorsPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handleIndicatorsPermission({
                              feature: Feature.INDICATORS,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={indicatorsPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {indicatorsPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapperDisabled>
                          <S.RadioDisabled />
                        </S.RadioWrapperDisabled>
                      </S.RightContent>

                      <S.RightContent>
                        <S.RadioWrapper
                          onClick={() => {
                            handleUsersPermission({
                              feature: Feature.USERS,
                              permission: Permission.ADMIN,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={usersPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            )}
                          >
                            {usersPermission.some(
                              (item) => item.permission === Permission.ADMIN,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapper
                          onClick={() => {
                            handleUsersPermission({
                              feature: Feature.USERS,
                              permission: Permission.VIEWER,
                            });
                          }}
                        >
                          <S.RadioButton
                            selected={usersPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            )}
                          >
                            {usersPermission.some(
                              (item) => item.permission === Permission.VIEWER,
                            ) ? (
                              <S.RadioSelected />
                              ) : null}
                          </S.RadioButton>
                        </S.RadioWrapper>
                        <S.RadioWrapperDisabled>
                          <S.RadioDisabled />
                        </S.RadioWrapperDisabled>
                      </S.RightContent>
                    </S.RightMain>
                  </S.ModalPermissionMain>

                  <S.ModalPermissionFooter>
                    <S.Cancel onClick={() => onCancelEdit()}>
                      {t('cancel')}
                    </S.Cancel>

                    <S.ConfirmButton onClick={() => handleConfirmPermissions()}>
                      {isConfirmLoading ? (
                        <Loading
                          type="spin"
                          width={20}
                          height={20}
                          color="#000"
                        />
                      ) : (
                        t('confirm')
                      )}
                    </S.ConfirmButton>
                  </S.ModalPermissionFooter>
                </S.ModalPermissionContainer>
              </S.ModalPermission>
            ) : null}
          </S.Widget>
        )}
      </S.Container>
    </LoggedArea>
  );
};

export default UsersPage;
